import React, { createContext, useContext, useState } from "react";

const LoaderContext = createContext();

export function useLoaderContext() {
  return useContext(LoaderContext);
}

export function LoaderProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = (loadingState) => {
    setIsLoading(loadingState);
  };

  return (
    <LoaderContext.Provider value={{ isLoading, setLoading }}>
      {children}
    </LoaderContext.Provider>
  );
}
