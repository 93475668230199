import React, { useEffect, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";

import "../node_modules/aos/dist/aos.css";
import "./styles/bootstrap.min.css";
import "animate.css";
import "./styles/boxicons.min.css";
import "./styles/flaticon.css";
import "./styles/slick.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "swiper/css";
import "swiper/css/bundle";
import "@fontsource/poppins";

import "./styles/style.css";
import "./styles/responsive.css";
import "./styles/custom.css";

import { LoaderProvider } from "./context/LoaderContext";

const Missing = lazy(() => import("./components/Missing"));

const Layout = lazy(() => import("./components/Layout"));
const Loader = lazy(() => import("./components/Loader"));

const Dashboard = lazy(() => import("./pages/dashboard"));
const Contact = lazy(() => import("./pages/contact"));
const About = lazy(() => import("./pages/about"));
const Career = lazy(() => import("./pages/career"));
const Job = lazy(() => import("./pages/job"));

//Services
const Services = lazy(() => import("./pages/services"));
const SingleService = lazy(() => import("./pages/singleService"));

//Industries
const Industries = lazy(() => import("./pages/industries"));
const SingleIndustry = lazy(() => import("./pages/singleIndustry"));

//CaseStudys
const CaseStudys = lazy(() => import("./pages/casestudys"));
const SingleCaseStudy = lazy(() => import("./pages/singleCaseStudy"));

//Blogs
const Blogs = lazy(() => import("./pages/blogs"));
const SingleBlog = lazy(() => import("./pages/singleBlog"));

//Cookies
const Cookies = lazy(() => import("./pages/coockies"));

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <LoaderProvider>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/service" element={<Services />} />
            <Route path="/service/:serviceUrl" element={<SingleService />} />

            <Route path="/industry" element={<Industries />} />
            <Route path="/industry/:industryUrl" element={<SingleIndustry />} />

            <Route path="/career" element={<Career />} />
            <Route path="/job/:jobUrl" element={<Job />} />

            <Route path="/case-study" element={<CaseStudys />} />
            <Route
              path="/case-study/:casestudyUrl"
              element={<SingleCaseStudy />}
            />

            <Route path="/blog" element={<Blogs />} />
            <Route path="/blog/:blogUrl" element={<SingleBlog />} />

            <Route path="/cookies" element={<Cookies />} />
          </Route>
          <Route path="*" element={<Missing />} />
        </Routes>
      </Suspense>
    </LoaderProvider>
  );
}

export default App;
